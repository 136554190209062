import { ExDestination, type OrderBase } from 'src/contracts/order';
import { log } from 'src/routes/widgets/SimpleOrderEntryWidget/logger';
import z from 'zod';

export const Order = z.enum(['MARKET', 'LIMIT']);

export const Route = z.enum(['SMA', 'ARRIVAL', 'TWAP', 'VWAP', 'QMOC', 'OPEN']);
type RouteType = z.infer<typeof Route>;

export const RouteLabelMap: { [key: string]: string } = {
    SMA: 'SOR',
    QMOC: 'CLOSE',
};

export const QuantityType = z.enum(['QUANTITY', 'NOTIONAL']);

export const Duration = z.enum(['day', 'ext']);

export const Instruction = {
    NONE: 'None',
    AGGRESSIVE: 'Aggressive',
    NEUTRAL: 'Neutral',
    PASSIVE: 'Passive',
} as const;

export const OrderAction = z.enum(['buy', 'sell']);
export type OrderActionType = z.infer<typeof OrderAction>;

const SimpleOrderBaseSchema = z.object({
    quantity: z.coerce.number(),
    duration: Duration,
    limitPrice: z.union([z.number(), z.string()]),
    quantityType: QuantityType,
});

export const SimpleOrderSchemaByRoute = {
    SMA: SimpleOrderBaseSchema.extend({
        route: z.literal('SMA'),
        order: Order,
        exDestination: z.literal(ExDestination.BAML),
        instruction: z.enum([Instruction.NONE]),
    }),
    ARRIVAL: SimpleOrderBaseSchema.extend({
        route: z.literal('ARRIVAL'),
        order: Order,
        exDestination: z.literal(ExDestination.CITADEL),
        instruction: z.enum([Instruction.AGGRESSIVE, Instruction.NEUTRAL, Instruction.PASSIVE]),
    }),
    TWAP: SimpleOrderBaseSchema.extend({
        route: z.literal('TWAP'),
        order: Order,
        exDestination: z.literal(ExDestination.BAML),
        instruction: z.enum([Instruction.NONE]),
    }),
    VWAP: SimpleOrderBaseSchema.extend({
        route: z.literal('VWAP'),
        order: Order,
        exDestination: z.literal(ExDestination.BAML),
        instruction: z.enum([Instruction.NONE]),
    }),
    QMOC: SimpleOrderBaseSchema.extend({
        route: z.literal('QMOC'),
        order: Order,
        exDestination: z.literal(ExDestination.BAML),
        instruction: z.enum([Instruction.NONE]),
    }),
    OPEN: SimpleOrderBaseSchema.extend({
        route: z.literal('OPEN'),
        order: Order,
        exDestination: z.literal(ExDestination.BAML),
        instruction: z.enum([Instruction.NONE]),
    }),
} as const;

export const SimpleOrderSchema = z
    .discriminatedUnion('route', [
        SimpleOrderSchemaByRoute.SMA,
        SimpleOrderSchemaByRoute.ARRIVAL,
        SimpleOrderSchemaByRoute.TWAP,
        SimpleOrderSchemaByRoute.VWAP,
        SimpleOrderSchemaByRoute.QMOC,
        SimpleOrderSchemaByRoute.OPEN,
    ])
    .default({
        route: Route.enum.SMA,
        quantity: 100,
        order: Order.enum.MARKET,
        duration: Duration.enum.day,
        instruction: Instruction.NONE,
        limitPrice: '',
        quantityType: QuantityType.enum.QUANTITY,
        exDestination: ExDestination.BAML,
    })
    .superRefine((data, ctx) => {
        // when order is limit, limitPrice is required
        if (data.order === 'LIMIT' && Number(data.limitPrice) <= 0) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Required for limit orders',
                path: ['limitPrice'],
            });
        }
    });
export type SimpleOrderSchemaType = z.infer<typeof SimpleOrderSchema>;

export const defaultSimpleOrderEntryValues = SimpleOrderSchema.parse(undefined);

export const mapOrderEntryFormDataToSimpleOrder = (orderEntryFormData: Omit<OrderBase, 'account'> | undefined) => {
    if (!orderEntryFormData) {
        return defaultSimpleOrderEntryValues;
    }

    // determine the route, default to SMA
    const route = Route.options.includes(orderEntryFormData.route?.toUpperCase() as unknown as RouteType)
        ? (orderEntryFormData.route as RouteType)
        : Route.enum.SMA;

    // grab our schema based on the route
    const schema = SimpleOrderSchemaByRoute[route];

    // parse the order field to determine a valid option
    const parsedOrder = schema.pick({ order: true }).safeParse({
        order: orderEntryFormData.type?.toUpperCase(),
    });
    const order = parsedOrder.success ? parsedOrder.data.order : schema.shape.order.options[0];

    // ensure duration is valid, it can be gtc, which is not a valid enum value for simple
    const parsedDuration = schema.pick({ duration: true }).safeParse({
        duration: orderEntryFormData.duration,
    });
    const duration = parsedDuration.success ? parsedDuration.data.duration : schema.shape.duration.options[0];

    const { data, success, error } = schema.safeParse({
        route,
        quantity: Number.isNaN(Number(orderEntryFormData.quantity)) ? 100 : Number(orderEntryFormData.quantity),
        duration,
        limitPrice: orderEntryFormData.price ? orderEntryFormData.price : '',
        order,

        // No equivalent fields for these so default to first available option of schema
        quantityType: schema.shape.quantityType.options[0],
        instruction: schema.shape.instruction.options[0],
        exDestination: schema.shape.exDestination.value,
    });

    if (success) {
        return data;
    }

    // log error and return default values
    log.error({
        message: 'Error mapping order entry form data to simple order',
        error,
        orderEntryFormData,
    });

    return defaultSimpleOrderEntryValues;
};
