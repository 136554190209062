import { signal } from '@preact/signals-react';
import type { ReadonlySignal } from '@preact/signals-react';
import { type SlidingWindow, type BarLength } from '@thinkalpha/language-services';
import type Highstock from 'highcharts/highstock';
import { produce } from 'immer';
import type { Interval, SessionType } from 'src/contracts/timeframe';
import type {
    ChartPane,
    ChartSettings,
    TimeSeriesWidgetModel as TimeSeriesWidgetModelContract,
    ChartAxis,
    ChartSeries,
} from 'src/contracts/workspace';
import { timeSeriesAxisNames, timeSeriesPaneNames } from 'src/features/chart';
import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import { randomString } from 'src/lib/util/randomString';
import type { TimeSeriesWidgetModel, TimeSeriesWidgetState } from 'src/models/TimeSeriesWidgetModel';
import { updateWidget } from 'src/store/actions/widgetAndChannel';
import { changeTimeSeriesSymbol } from 'src/store/actions/widgets/timeSeries';
import type { ReactBindings } from 'src/types/bindings';

@injectable()
export class TimeSeriesWidgetModelImpl extends ReactiveInjectable implements TimeSeriesWidgetModel {
    addSeries(newSeries: ChartSeries, seriesPane: ChartPane, seriesAxis: ChartAxis) {
        let newPanes = this.panes;
        newPanes = addNewSeries(newSeries, seriesPane, seriesAxis, newPanes);

        this.update({
            panes: newPanes,
        });
    }

    get barLength(): SlidingWindow {
        return this.widget.barLength;
    }

    changeSymbol(newSymbol: string) {
        const { tabId } = this.widgetData;
        return this.store.dispatch(changeTimeSeriesSymbol(tabId, newSymbol));
    }

    get channelId(): string | null {
        return this.widgetData.channelId;
    }

    get chartSettings(): ChartSettings | undefined {
        return this.widget.chartSettings;
    }

    #chartSizeSignal = signal<{ height: number; width: number }>({ height: 150, width: 500 });
    get chartSize(): ReadonlySignal<{ height: number; width: number }> {
        return this.#chartSizeSignal;
    }

    updateChartSize(size: { height: number; width: number }) {
        this.#chartSizeSignal.value = size;
    }

    constructor(
        @inject('WidgetDataModel') @reacts private widgetData: ReactBindings['WidgetDataModel'],
        @inject('Store') private store: ReactBindings['Store'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);
    }

    deleteSeries(seriesId: string) {
        let newPanes = [...this.panes];
        newPanes = deleteSeriesById(newPanes, seriesId);
        newPanes = deleteEmptyPanesAndAxes(newPanes);
        newPanes = confirmPaneAndAxisNames(newPanes);
        this.update({
            panes: newPanes,
        });
    }

    #hoverDataSignal = signal<Highstock.TooltipFormatterContextObject[] | null>(null);
    get hoverData(): ReadonlySignal<Highstock.TooltipFormatterContextObject[] | null> {
        return this.#hoverDataSignal;
    }

    updateHoverData(hoverData: Highstock.TooltipFormatterContextObject[] | null) {
        this.#hoverDataSignal.value = hoverData;
    }

    init(tabId: string) {
        this.widgetData.init(tabId);
    }

    get interval(): Interval {
        return this.widget.interval;
    }

    moveSeriesToAxis(series: ChartSeries, newPane: ChartPane, newAxis: ChartAxis) {
        let newPanes = [...this.panes];
        newPanes = deleteSeriesById(newPanes, series.id);
        newPanes = deleteEmptyPanesAndAxes(newPanes);
        newPanes = confirmPaneAndAxisNames(newPanes);
        newPanes = addNewSeries(series, newPane, newAxis, newPanes);

        this.update({
            panes: newPanes,
        });
    }

    get panes(): ChartPane[] {
        return this.widget.panes;
    }

    get plotBandColors(): Record<string, string> {
        return this.widget.plotBandColors;
    }

    get session(): SessionType {
        return this.widget.session;
    }

    setBarLength(opt: BarLength) {
        this.update({ barLength: opt });
    }

    setInterval(interval: Interval) {
        this.update({ interval });
    }

    setSession(session: SessionType) {
        this.update({ session });
    }

    setSymbol(symbol: string) {
        this.update({ symbol });
    }

    setChartSettings(chartSettings: ChartSettings) {
        this.update({ chartSettings });
    }

    get symbol(): string {
        return this.widget.symbol;
    }

    get type(): 'time-series' {
        return 'time-series';
    }

    update(updates: Partial<TimeSeriesWidgetState>) {
        const { tabId } = this.widgetData;
        this.store.dispatch(
            updateWidget({
                tabId,
                widgetUpdates: updates,
            }),
        );
    }

    updateSeries(seriesId: string, seriesUpdate: Partial<ChartSeries>) {
        const newPanes = produce(this.panes, (draft) => {
            for (const pane of draft) {
                for (const axis of pane.axes) {
                    for (let si = 0; si < axis.series.length; si++) {
                        if (axis.series[si].id === seriesId) {
                            // TODO AMP: remove any typing; figure out why a partial can't apply to the whole
                            axis.series[si] = { ...axis.series[si], ...(seriesUpdate as any) };
                            return;
                        }
                    }
                }
            }
        });
        this.update({
            panes: newPanes,
        });
    }

    private get widget(): TimeSeriesWidgetModelContract {
        return this.widgetData.widget as TimeSeriesWidgetModelContract;
    }
}

function addNewSeries(
    series: ChartSeries,
    seriesPane: ChartPane,
    seriesAxis: ChartAxis,
    panes: ChartPane[],
): ChartPane[] {
    const newSeries = { ...series };
    const newSeriesPane = { ...seriesPane };
    const newSeriesAxis = { ...seriesAxis };

    newSeries.id = randomString();
    if (newSeriesPane.id === '') {
        newSeriesPane.id = timeSeriesPaneNames[panes.length];
    }
    if (newSeriesAxis.id === '') {
        // Find out how many axes are already in the pane, then use next name in list
        const numberAxes = panes.find((p) => p.id === newSeriesPane.id)?.axes.length || 0;
        newSeriesAxis.id = timeSeriesAxisNames[numberAxes];
    }
    const newPanes = produce(panes, (draft) => {
        for (const pane of draft) {
            for (const axis of pane.axes) {
                if (pane.id === newSeriesPane.id && axis.id === newSeriesAxis.id) {
                    axis.series.push(newSeries);
                    return;
                }
            }
            if (pane.id === newSeriesPane.id) {
                pane.axes.push({ ...newSeriesAxis, series: [newSeries] });
                return;
            }
        }
        draft.push({ ...newSeriesPane, axes: [{ ...newSeriesAxis, series: [newSeries] }] });
    });
    return newPanes;
}

function deleteSeriesById(panes: ChartPane[], seriesId: string): ChartPane[] {
    const newPanes = produce(panes, (draft) => {
        for (let pi = 0; pi < panes.length; pi++) {
            const pane = draft[pi];
            for (let ai = 0; ai < pane.axes.length; ai++) {
                const axis = pane.axes[ai];
                const seriesIndex = axis.series.findIndex((s) => s.id === seriesId);
                if (seriesIndex !== -1) {
                    if (axis.series.length === 1) {
                        draft[pi].axes[ai].series = [];
                    } else {
                        axis.series.splice(seriesIndex, 1);
                    }
                }
            }
        }
    });
    return newPanes;
}
function deleteEmptyPanesAndAxes(panes: ChartPane[]): ChartPane[] {
    // Run thru all panes and axes and remove any that don't have a series in them
    const newPanes = produce(panes, (draft) => {
        for (let pi = panes.length - 1; pi >= 0; pi--) {
            const pane = draft[pi];
            for (let ai = pane.axes.length - 1; ai >= 0; ai--) {
                const axis = pane.axes[ai];
                if (axis.series.length === 0) {
                    // If no series, remove the axis
                    if (pane.axes.length === 1) {
                        draft[pi].axes = [];
                    } else {
                        pane.axes.splice(ai, 1);
                    }
                    // If no axes, remove the pane
                    if (pane.axes.length === 0) {
                        if (panes.length === 1) {
                            draft = [];
                        } else {
                            draft.splice(pi, 1);
                        }
                    }
                }
            }
        }
    });
    return newPanes;
}

function confirmPaneAndAxisNames(workingPanes: ChartPane[]): ChartPane[] {
    // Names for panes and axes go in order, so rename if things are deleted leaving holes
    const newPanes = produce(workingPanes, (draft) => {
        for (let pi = 0; pi < workingPanes.length; pi++) {
            const pane = draft[pi];
            draft[pi] = { ...pane, id: timeSeriesPaneNames[pi] };
            for (let ai = 0; ai < draft[pi].axes.length; ai++) {
                const axis = pane.axes[ai];
                draft[pi].axes[ai] = { ...axis, id: timeSeriesAxisNames[ai] };
            }
        }
    });
    return newPanes;
}
