import { LocalDate } from '@js-joda/core';
import type { ConcreteIndicator } from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import type { EventType } from 'src/components/ui/EventList';
import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import type { EventsWidgetModel } from 'src/models/EventsWidgetModel';
import { getWholeSlugMapQuery } from 'src/queries/slugMap';
import { type Logger } from 'src/services/Logger';
import { createAlphaLensFromSymbol, createChartFromSymbol } from 'src/store/actions/container';
import {
    userSetEventsEventTypes,
    userSetEventsGoToDate,
    userSetEventsSymbolFilter,
    userSetEventsUniverse,
} from 'src/store/actions/widgets/events';
import { userDoubleClickedSymbolFromTable } from 'src/store/actions/widgets/results';
import type { EventsWidgetViewModel } from 'src/store/types';
import type { ReactBindings } from 'src/types/bindings';

interface EventsWidgetModelState {
    changeCloseSlug: ConcreteIndicator | null;
    symbolSlug: ConcreteIndicator | null;
}

@injectable()
export class EventsWidgetModelImpl extends ReactiveInjectable implements EventsWidgetModel {
    #state: EventsWidgetModelState;
    #logger: Logger;

    constructor(
        @inject('WidgetDataModel') @reacts private widgetData: ReactBindings['WidgetDataModel'],
        @inject('Store') @reacts private store: ReactBindings['Store'],
        @inject('QueryClient') private readonly queryClient: ReactBindings['QueryClient'],
        @inject('Logger') logger: Logger,
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);

        this.#state = {
            changeCloseSlug: null,
            symbolSlug: null,
        };

        this.#logger = logger.getSubLogger({ name: 'EventsWidgetModel' });
    }

    async init(tabId: string) {
        this.widgetData.init(tabId);

        const allSlugs = await this.queryClient.fetchQuery(getWholeSlugMapQuery());

        this.state = {
            symbolSlug: allSlugs['symbol'],
            changeCloseSlug: allSlugs['symbol::changeClose'],
        };

        // this.#logger.debug({ message: 'got slugmap', allSlugs });
    }

    get widget() {
        return this.widgetData.widget as EventsWidgetViewModel;
    }

    private resetState() {
        this.state = {
            changeCloseSlug: this.#state.changeCloseSlug,
            symbolSlug: this.#state.symbolSlug,
        };
        this.store.dispatch(userSetEventsSymbolFilter(this.widgetData.tabId, null));
        this.store.dispatch(userSetEventsGoToDate(this.widgetData.tabId, null));
    }

    onGoToDate(date: LocalDate | null) {
        this.store.dispatch(userSetEventsGoToDate(this.widgetData.tabId, date));
    }

    get gotoDate() {
        return this.widget.gotoDate;
    }

    onSelectEventTypes(eventTypes: EventType[]): void {
        this.store.dispatch(userSetEventsEventTypes(this.widgetData.tabId, eventTypes));
    }

    get universe() {
        return this.widget.universeId;
    }

    set universe(universe: string | null) {
        this.resetState();
        this.store.dispatch(userSetEventsUniverse(this.widgetData.tabId, universe));
    }

    @reacts private set state(newState: EventsWidgetModelState) {
        this.#state = newState;
    }

    onSelectSymbol(symbol: string | null) {
        if (symbol) {
            this.store.dispatch(userDoubleClickedSymbolFromTable(this.widgetData.tabId, symbol));
        }
    }

    onChangeSymbolFilter(symbolFilter: string): void {
        this.store.dispatch(userSetEventsSymbolFilter(this.widgetData.tabId, symbolFilter));
    }

    openChartForSymbol(symbol: string) {
        this.store.dispatch(
            createChartFromSymbol({
                defaultSymbol: symbol,
                channelId: this.widget.channelId,
            }),
        );
    }

    openLensForSymbol(symbol: string) {
        this.store.dispatch(
            createAlphaLensFromSymbol({
                defaultSymbol: symbol,
                channelId: this.widget.channelId,
            }),
        );
    }

    get changeCloseSlug() {
        return this.#state.changeCloseSlug;
    }

    get symbolSlug() {
        return this.#state.symbolSlug;
    }

    get symbolFilter() {
        return this.widget.symbolFilter ?? '';
    }

    set symbolFilter(value: string) {
        this.store.dispatch(userSetEventsSymbolFilter(this.widgetData.tabId, value === '' ? null : value));
    }
}
